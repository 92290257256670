<template>
  <quick-quote-page
    id="Plans"
    :optionsname="$options.name"
  >
    <template #keyselection>
      <select-customer
        v-if="customers.data.length > 1"
        id="2-maintain"
        v-model="customers.selectedItem"
        :appendhint="`to view its ${displayAsPlural}`"
      />
    </template>

    <template #content>
      <plan-table
        ref="maintenancePlanTable"
      />
    </template>
  </quick-quote-page>
</template>

<script>
  import { get } from 'vuex-pathify'
  import QuickQuotePage from '@/components/QuickQuotePage'
  import SelectCustomer from '@/components/keys/SelectCustomer.vue'
  import PlanTable from '@/components/maintenance/tables/PlanTable'

  export default {
    name: 'PlansView',

    components: {
      QuickQuotePage,
      SelectCustomer,
      PlanTable,
    },

    data () {
      return {}
    },

    computed: {
      users: get('user/users'),
      customers: get('customer/customers'),
      displayAsPlural: get('plan/displayAsPlural'),
    },
  }
</script>

<style scoped>

</style>
